import React, { useEffect } from "react";
import Hero from "../../components/hero";
import Intro from "../../components/intro";
import Member from "../../components/member";
import Service from "../../components/service";
import Map from "../../components/map";
import store from "../../store.js";

export default function Main(props) {
  window.scrollTo(0, 0);
  useEffect(() => {
    store.dispatch({
      type: "CHANGE_NAV_COLOR",
      navColor: "transparent",
    });
  }, []);

  return (
    <>
      <Hero />
      <Intro />
      <Member />
      <Service />
      <Map />
    </>
  );
}
