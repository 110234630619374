import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import store from "../../store.js";
import { mobileMinWidth, pcMinWidth } from "../dimension";
import ReactPlayer from "react-player";
import logo from "../../videos/logo.mp4";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

export default function Hero() {
  const scrollSensor = useRef(null);

  function onScroll() {
    if (scrollSensor.current == null) return;

    let scrollSensorBottom =
      scrollSensor.current.getBoundingClientRect().bottom;

    let navBarHeight = document
      .getElementById("nav-bar")
      .getBoundingClientRect().height;

    if (scrollSensorBottom - navBarHeight <= 0) {
      store.dispatch({ type: "CHANGE_NAV_COLOR", navColor: "opaque" });
    } else {
      store.dispatch({ type: "CHANGE_NAV_COLOR", navColor: "transparent" });
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Box
      ref={scrollSensor}
      sx={{
        width: "100%",
        minWidth: `${useMediaQuery(isMobile) ? mobileMinWidth : pcMinWidth}`,
        height: `${useMediaQuery(isMobile) ? "37rem" : "70rem"}`,
        backgroundColor: "primary.main",

        "& video": {
          objectFit: "cover",
        },
      }}
    >
      <ReactPlayer
        url={logo}
        playing={true}
        muted={true}
        width="100%"
        height="100%"
      />
    </Box>
  );
}
