import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LogoWhite from "../../images/logo/logo-white.svg";
import LogoNavy from "../../images/logo/logo-navy.svg";
import ButtonBase from "@mui/material/ButtonBase";
import { Link } from "react-router-dom";
import store from "../../store.js";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { mobileMinWidth } from "../dimension";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

function Logo() {
  let logo = LogoWhite;

  if (useSelector((state) => state.navColor) === "transparent") {
    logo = LogoWhite;
  } else {
    logo = LogoNavy;
  }

  let logoWidth = 100;
  if (useMediaQuery(isMobile)) {
    logoWidth = 100;
  } else {
    logoWidth = 120;
  }

  return (
    <ButtonBase
      sx={{
        height: 40,
        width: logoWidth + 30,
      }}
    >
      <Link to="/">
        <Box
          sx={{ width: logoWidth }}
          component="img"
          alt="logo"
          src={logo}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        />
      </Link>
    </ButtonBase>
  );
}

function MobileNav() {
  const [menuOnOff, setMenuOnOff] = useState(false);

  const turnOnMenu = () => {
    setMenuOnOff(true);
  };

  const turnOffMenu = () => {
    window.scrollTo(0, 0);
    setMenuOnOff(false);
  };

  const MobileNavButton = (props) => {
    return (
      <Box
        sx={{
          width: "20rem",
          height: "4rem",
          m: "1rem 0rem",
          backgroundColor: "primary.main",
          borderRadius: "5px",
          boxShadow: 5,
        }}
      >
        <Link to={props.link} style={{ textDecoration: "none" }}>
          <Button
            sx={{
              width: "100%",
              height: "100%",
              color: "primary.background",
              borderRadius: "1.2rem",
              fontSize: "1.6rem",
            }}
            onClick={() => {
              turnOffMenu();
              store.dispatch({
                type: "CHANGE_NAV_COLOR",
                navColor: props.navColor,
              });
            }}
          >
            {props.content}
          </Button>
        </Link>
      </Box>
    );
  };

  const MobileDrawer = () => {
    return (
      <Drawer anchor={"top"} open={menuOnOff} onClose={turnOffMenu}>
        <Box
          sx={{
            width: "100%",
            minWidth: mobileMinWidth,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            p: "0rem 0rem 1rem 0rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItem: "center",
              width: "100%",
              p: "1rem 0rem 0rem 0rem",
            }}
          >
            <IconButton
              sx={{
                width: "5rem",
                height: "5rem",
                m: "0rem 1rem 0rem 0rem",
                color: "#404040",
                backgroundColor: "primary.background",
                "& svg": {
                  color: "primary.main",
                },
              }}
              aria-label="menu"
              onClick={turnOffMenu}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <MobileNavButton link="/" content="법무법인 소개" />
          <MobileNavButton link="/member/m0" content="구성원" />
          <MobileNavButton link="/service/s0" content="주요 업무" />
          <MobileNavButton link="/map" content="오시는 길" />
        </Box>
      </Drawer>
    );
  };

  let navSx = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: "0rem",
    m: "-5rem 0rem 0rem 0rem",
    width: "100%",
    minWidth: mobileMinWidth,
    height: "5rem",
    zIndex: "101",
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "rgba(255, 255, 255, 1)",
  };

  if (useSelector((state) => state.navColor) === "transparent") {
    navSx = {
      ...navSx,
      ...{
        backgroundColor: "rgba(255, 255, 255, 0)",
        color: "rgba(255, 255, 255, 1)",
      },
    };
  } else {
    navSx = {
      ...navSx,
      ...{
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "rgba(255, 255, 255, 1)",
      },
    };
  }

  return (
    <Box id="nav-bar" sx={navSx}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Logo />
        <IconButton
          sx={{ width: "4rem", height: "4rem", m: "0rem 0.5rem 0rem 0rem" }}
          aria-label="menu"
          onClick={turnOnMenu}
        >
          <MenuIcon sx={{ fontSize: "2.4rem" }} />
        </IconButton>
      </Box>
      <MobileDrawer />
    </Box>
  );
}

function PcNav() {
  const PcNavButton = (props) => {
    function changeNavColor() {
      store.dispatch({ type: "CHANGE_NAV_COLOR", navColor: props.navColor });
      window.scrollTo(0, 0);
    }

    return (
      <Link to={props.link} style={{ textDecoration: "none" }}>
        <Button
          sx={{
            fontSize: "1.5rem",
            color: "white",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
          onClick={changeNavColor}
        >
          {props.content}
        </Button>
      </Link>
    );
  };

  let navSx = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: "0px",
    m: "-50px 0px 0px 0px",
    width: "100%",
    minWidth: "1024px",
    height: "50px",
    zIndex: "101",
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "rgba(255, 255, 255, 1)",
  };

  if (useSelector((state) => state.navColor) === "transparent") {
    navSx = {
      ...navSx,
      ...{
        backgroundColor: "rgba(255, 255, 255, 0)",
        color: "rgba(255, 255, 255, 1)",
      },
    };
  } else {
    navSx = {
      ...navSx,
      ...{
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "rgba(255, 255, 255, 1)",
      },
    };
  }

  return (
    <Box id="nav-bar" sx={navSx}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "102.4rem",
        }}
      >
        <Logo />
        <Box>
          <PcNavButton
            link="/"
            content="법무법인 소개"
            navColor="transparent"
          />
          <PcNavButton link="../member/m0" content="구성원" navColor="opaque" />
          <PcNavButton
            link="../service/s0"
            content="주요 업무"
            navColor="opaque"
          />
          <PcNavButton link="../map" content="오시는 길" navColor="opaque" />
        </Box>
      </Box>
    </Box>
  );
}

export default function Nav() {
  useEffect(() => {
    if (!window.KaKao && !window.Kakao.isInitialized()) {
      window.Kakao.init("fdeb82a147eaddebcd488491f640f948");
    }
  }, []);

  if (useMediaQuery(isMobile)) {
    return <MobileNav />;
  } else {
    return <PcNav />;
  }
}
