import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import MainContainer from "../main-container";
import store from "../../store.js";
import { IntroCard } from "./intro-elements";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

function isVisible(scrollSensor) {
  if (scrollSensor.current == null) return false;

  let scrollSensorTop = scrollSensor.current.getBoundingClientRect().top;
  let scrollSensorBottom = scrollSensor.current.getBoundingClientRect().bottom;

  if (scrollSensorBottom > 0 && scrollSensorTop + 50 < window.innerHeight) {
    return "visible";
  } else {
    return "invisible";
  }
}

function CardBox() {
  const titles = ["다양한 경험", "다미의 목표", "3인 전담팀", "합리적 비용"];

  const describes = [
    "국가기관, 공공기관, 건설회사, 금융회사 및 법무법인에서 다양한 경험을 쌓아 왔습니다.",
    "의뢰인이 신뢰하고 책임을 맡길 수 있는 법무법인을 만들자는 목표 아래 모였습니다.",
    "사법연수원 42기 동기인 3명의 변호사가 전담 팀을 구성하여 사건을 함께 수행합니다.",
    "최소한의 비용으로 최대한의 결과를 얻기위해 노력하고, 무리한 분쟁을 유도하지 않습니다.",
  ];

  const scrollSensor = useRef(null);

  function onScroll() {
    if (scrollSensor.current === null) return;

    store.dispatch({
      type: "SET_INTRO_CARD_VISIBLE",
      introCardVisible: isVisible(scrollSensor),
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  if (useMediaQuery(isMobile)) {
    return (
      <Box
        ref={scrollSensor}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItem: "center",
          m: "10rem 0rem 10rem 0",
          width: "34rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "34rem",
            m: "0rem 0rem 2rem 0rem",
          }}
        >
          <IntroCard
            tit={titles[0]}
            desc={describes[0]}
            imgidx="0"
            delay="delay-m-0"
          />
          <IntroCard
            tit={titles[1]}
            desc={describes[1]}
            imgidx="1"
            delay="delay-m-1"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "34rem",
          }}
        >
          <IntroCard
            tit={titles[2]}
            desc={describes[2]}
            imgidx="2"
            delay="delay-m-2"
          />
          <IntroCard
            tit={titles[3]}
            desc={describes[3]}
            imgidx="3"
            delay="delay-m-3"
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        ref={scrollSensor}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          m: "10rem 0 10rem 0",
          width: "96rem",
        }}
      >
        <IntroCard
          tit={titles[0]}
          desc={describes[0]}
          imgidx="0"
          delay="delay-0"
        />
        <IntroCard
          tit={titles[1]}
          desc={describes[1]}
          imgidx="1"
          delay="delay-1"
        />
        <IntroCard
          tit={titles[2]}
          desc={describes[2]}
          imgidx="2"
          delay="delay-2"
        />
        <IntroCard
          tit={titles[3]}
          desc={describes[3]}
          imgidx="3"
          delay="delay-3"
        />
      </Box>
    );
  }
}

export default function Intro() {
  const theme = useTheme();

  return (
    <MainContainer
      outerConColor={theme.palette.primary.background}
      innerConColor={theme.palette.secondary.main}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontSize: `${useMediaQuery(isMobile) ? "3.3rem" : "4rem"}`,
            m: "10rem 1.5rem 7rem 1.5rem",
          }}
        >
          법무법인 다미는 맡은 책임을 다합니다.
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: `${useMediaQuery(isMobile) ? "2.5rem" : "3.2rem"}`,
            m: "0rem 1.5rem 7rem 1.5rem",
          }}
        >
          다미는 '남의 책임을 맡아 줌 또는 그 책임'을 뜻하는 순 우리말 입니다.
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: `${useMediaQuery(isMobile) ? "2.5rem" : "3.2rem"}`,
            m: "0rem 1.5rem 0rem 1.5rem",
          }}
        >
          의뢰인이 신뢰하고 책임을 맡길 수 있는 법무법인을 만들기 위해 최선을
          다하겠습니다.
        </Box>
        <CardBox />
      </Box>
    </MainContainer>
  );
}
