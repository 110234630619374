import React from "react";
import Box from "@mui/material/Box";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";
import { mobileMinWidth, pcMinWidth } from "../dimension";

function InnerContainer(props) {
  return (
    <Box
      sx={{
        width: `${useMediaQuery(isMobile) ? "100%" : "1024px"}`,
        minWidth: `${useMediaQuery(isMobile) ? mobileMinWidth : pcMinWidth}`,
        backgroundColor: props.innerContainerColor,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        p: "0px 0px",
      }}
    >
      {props.children}
    </Box>
  );
}

function OuterContainer(props) {
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: `${useMediaQuery(isMobile) ? mobileMinWidth : pcMinWidth}`,
        backgroundColor: props.outerContainerColor,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.children}
    </Box>
  );
}

export default function MainContainer(props) {
  return (
    <OuterContainer outerContainerColor={props.outerContainerColor}>
      <InnerContainer innerContainerColor={props.innerContainerColor}>
        {props.children}
      </InnerContainer>
    </OuterContainer>
  );
}
