import React from "react";
import Box from "@mui/material/Box";
import { Header, Intro, Career } from "./member-elements";
import Photo from "../../images/member/member-third.jpg";

export function Member2() {
  return (
    <Box>
      <Header
        photo={Photo}
        name="하동규"
        position="파트너 변호사"
        contacts={[
          ["Tel.", "032-710-3930"],
          ["Fax.", "032-710-3931"],
          ["Email.", "hdk@damilaw.kr"],
        ]}
      />
      <Intro
        intro={
          <>
            하동규 변호사는 사법연수원 수료 후 10여년 동안 송무변호사로 활동해
            왔습니다.
          </>
        }
      />
      <Intro
        intro={
          <>
            하동규 변호사는 한국주택금융공사, 한국자산관리공사, 신용보증기금,
            기술보증기금, 국민행복기금 등을 포함하는 금융공기업,
            한국토지주택공사, 인천도시공사 등을 포함하는 준시장형 공기업,
            서울보증보험(주), 농협은행(주), 농업협동조합중앙회(및 지역농·축협),
            옹진수산업협동조합 등을 포함하는 금융회사, 인천교육청, 인천광역시
            등을 대리하여 1,000건 이상의 소송을 수행하였고, 소송 등과 관련된
            자문업무를 수행하였습니다.
          </>
        }
      />
      <Career
        title="학력"
        careers={[<>- 이서고등학교 졸업</>, <>- 성균관대학교 졸업</>]}
      />
      <Career
        title="주요 경력"
        careers={[
          <>- 2021~현재 법무법인 다미 파트너 변호사</>,
          <>- 2021~현재 인천광역시 공유재산심의회 위원</>,
          <>- 2021~현재 인천광역시 계양구 정보공개심의회 위원</>,
          <>- 2020~현재 대법원 국선변호인</>,
          <>- 2010 제52회 사법시험 합격</>,
          <>- 2012 대구지방검찰청 서부지청 검사직무대리</>,
          <>- 2012 서울북부지방법원 조정위원</>,
          <>- 2013 제42기 사법연수원 수료</>,
          <>- 2013~2021 법무법인 헌암 소속 변호사</>,
        ]}
      />
    </Box>
  );
}
