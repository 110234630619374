import React from "react";
import Box from "@mui/material/Box";
import { Header, Intro, Career } from "./member-elements";
import Photo from "../../images/member/member-second.jpg";

export function Member1() {
  return (
    <Box>
      <Header
        photo={Photo}
        name="김진모"
        position="파트너 변호사"
        contacts={[
          ["Tel.", "032-710-3930"],
          ["Fax.", "032-710-3930"],
          ["Email.", "kjm@damilaw.kr"],
        ]}
      />
      <Intro
        intro={
          <>
            김진모 변호사는 사법연수원 수료 후 송무변호사로서 법무법인 헌암에서
            근무하며 한국주택금융공사, 한국자산관리공사, 신용보증기금,
            한국토지주택공사 등을 대리하며 수 백건의 소송을 수행하였습니다.
          </>
        }
      />
      <Intro
        intro={
          <>
            2015년부터 서울지방국세청 징세관실에 근무하며 체납관련업무에 관한
            자문업무를 수행하였습니다.
          </>
        }
      />
      <Intro
        intro={
          <>
            2016년부터는 농림수산업자 신용보증기금(관리기관 농협중앙회)에서 약
            5년간 위 기금 유일한 사내변호사로 근무하여 기금 운영, 구상권 행사
            관련 자문 및 소송업무를 수행하였습니다.
          </>
        }
      />
      <Career
        title="학력"
        careers={[<>- 휘문고등학교 졸업</>, <>- 한양대학교 졸업</>]}
      />
      <Career
        title="주요 경력"
        careers={[
          <>- 2021~현재 법무법인 다미 파트너 변호사</>,
          <>- 2010 제52회 사법시험 합격</>,
          <>- 2013 제42기 사법연수원 수료</>,
          <>- 2013~2015 법무법인 헌암 소속 변호사</>,
          <>- 2015~2016 서울지방국세청 징세관실</>,
          <>- 2016~2021 농협중앙회(농림수산업자 신용보증기금 관리기관)</>,
        ]}
      />
    </Box>
  );
}
