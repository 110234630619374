import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import store from "../../store.js";
import { useSelector } from "react-redux";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

function ContactCard(props) {
  const contactDatas = [
    ["T", "tel", "032-710-3930"],
    ["F", "fax", "032-710-3931"],
    ["E", "e-mail", "dami@damilaw.kr"],
  ];

  if (useMediaQuery(isMobile)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "16rem",
          m: "1rem 0rem 0rem 0rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "2.2rem",
            height: "2.2rem",
            m: "0rem 1rem 0rem 0rem",
            borderRadius: "50%",
            backgroundColor: "primary.background",
          }}
        >
          <Box
            sx={{
              fontSize: "1.6rem",
              fontWeight: 600,
              color: "primary.main",
            }}
          >
            {contactDatas[props.conidx][0]}
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "1.4rem",
            color: "primary.background",
          }}
        >
          {contactDatas[props.conidx][2]}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "15rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "6rem",
            height: "6rem",
            m: "1rem 0rem 1rem 0rem",
            backgroundColor: "primary.background",
            borderRadius: "50%",
          }}
        >
          <Box
            sx={{
              fontSize: "1.8rem",
              textAlign: "center",
              fontWeight: "700",
              color: "primary.main",
            }}
          >
            {contactDatas[props.conidx][1]}
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "1.8rem",
            textAlign: "center",
            fontWeight: "500",
            color: "white",
          }}
        >
          {contactDatas[props.conidx][2]}
        </Box>
      </Box>
    );
  }
}

function ContactCardBox() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "primary.main",
        p: "1rem 0rem 1rem 0rem",
      }}
    >
      <Box
        sx={{
          fontSize: `${useMediaQuery(isMobile) ? "1.2rem" : "1.8rem"}`,
          textAlign: "center",
          fontWeight: "500",
          color: "primary.background",
          m: "1rem 0rem 0rem 0rem",
        }}
      >
        인천광역시 미추홀구 소성로 171, 대흥동 2층 204호
      </Box>
      <Box
        sx={{
          fontSize: `${useMediaQuery(isMobile) ? "1.2rem" : "1.8rem"}`,
          textAlign: "center",
          fontWeight: "500",
          color: "primary.background",
          m: "0.5rem 0rem 1.5rem 0rem",
        }}
      >
        (학익동, 대흥평창로시스빌딩)
      </Box>
      <Box
        sx={{
          width: "95%",
          height: "0.2rem",
          backgroundColor: "primary.background",
          m: "0rem 0rem 0.5rem 0rem",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={
            useMediaQuery(isMobile)
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100%",
                }
          }
        >
          <ContactCard conidx="0" />
          <ContactCard conidx="1" />
          <ContactCard conidx="2" />
        </Box>
      </Box>
    </Box>
  );
}

export function NaverMap() {
  let zoomControlStyle = null;

  if (useMediaQuery(isMobile)) {
    zoomControlStyle = window.naver.maps.ZoomControlStyle.SMALL;
  } else {
    zoomControlStyle = window.naver.maps.ZoomControlStyle.LARGE;
  }

  function isVisible(scrollSensor) {
    if (scrollSensor.current == null) return false;

    let scrollSensorTop = scrollSensor.current.getBoundingClientRect().top;
    let scrollSensorBottom =
      scrollSensor.current.getBoundingClientRect().bottom;

    if (scrollSensorBottom > 0 && scrollSensorTop + 50 < window.innerHeight) {
      return "visible";
    } else {
      return "invisible";
    }
  }

  const scrollSensor = useRef(null);

  function onScroll() {
    if (scrollSensor.current === null) return;

    store.dispatch({
      type: "SET_MAP_VISIBLE",
      mapVisible: isVisible(scrollSensor),
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  // center: new window.naver.maps.LatLng(37.441862, 126.667368),

  let mobileOrPc = useMediaQuery(isMobile);

  useEffect(() => {
    const mapOptions = {
      center: new window.naver.maps.LatLng(
        37.44191792026676,
        126.6671841045985
      ),
      scaleControl: true,
      zoom: 16,
      zoomControl: true,
      zoomControlOptions: {
        position: window.naver.maps.Position.TOP_RIGHT,
        style: zoomControlStyle,
      },
      scrollWheel: isMobile ? false : true,
    };

    const map = new window.naver.maps.Map("naver-map-container", mapOptions);

    var infowindow = new window.naver.maps.InfoWindow({
      content:
        "<p style='font-size: 1.2rem; font-weight: bolder; padding: 0.5rem 0.5rem 0.5rem 0.5rem; margin: 0rem'>법무법인 다미</p>",
    });

    var marker = new window.naver.maps.Marker({
      position: new window.naver.maps.LatLng(
        37.44191792026676,
        126.6671841045985
      ),
      map: map,
    });

    window.naver.maps.Event.addListener(marker, "click", function (e) {
      if (infowindow.getMap()) {
        infowindow.close();
      } else {
        infowindow.open(map, marker);
      }
    });

    infowindow.open(map, marker);
  }, []);

  const theme = useTheme();

  return (
    <Box
      className={`${useSelector((state) => state.mapVisible)}`}
      ref={scrollSensor}
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: "0px",
        backgroundColor: "primary.background",
        opacity: 0.5,
        transform: "translateY(15%)",
        borderRadius: "10px",
        boxShadow: 5,
        "&.visible": {
          opacity: 1,
          transform: "translateY(0%)",
          transition: "1s",
        },
      }}
    >
      <Box
        id="naver-map-container"
        sx={{
          width: `${useMediaQuery(isMobile) ? "30rem" : "96rem"}`,
          height: `${useMediaQuery(isMobile) ? "30rem" : "52rem"}`,
        }}
      />
      <ContactCardBox />
    </Box>
  );
}
