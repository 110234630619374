import React from "react";
import MainContainer from "../main-container";
import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import { NaverMap } from "./map-elements";

export default function Map() {
  const theme = useTheme();

  return (
    <MainContainer
      outerContainerColor={theme.palette.secondary.main}
      innerContainerColor={theme.palette.secondary.main}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          p: "0rem 0rem 10rem 0rem",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "4rem",
            m: "10rem 1rem 10rem 1rem",
          }}
        >
          오시는 길
        </Box>
        <NaverMap />
      </Box>
    </MainContainer>
  );
}
