import React from "react";
import {
  Title,
  SubTitle,
  Paragraph,
  TelButton,
  EmphasisParagraph,
} from "./service-elements";
import Box from "@mui/material/Box";

export function Service2(props) {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.background",
        borderRadius: "1.2rem",
      }}
    >
      <Title title={<>명도소송</>} />
      <SubTitle subtitle={<>1. 들어가며</>} />
      <Paragraph
        paragraph={
          <>
            부동산에 관한 임대차 등의 계약관계가 기간만료, 해지, 해제, 취소,
            무효 등을 원인으로 종료하였음에도 불구하고 상대방이 점유를 풀지
            않으면서 부동산 인도를 거부하는 경우, 당사자(소유자, 임대인 등)는
            상대방(임차인 등)을 상대로 인도소송(명도소송)을 제기할 수 있고, 이후
            판결 등의 집행권원이 확보되면 강제집행을 신청하여 부동산을 인도받을
            수 있습니다.
          </>
        }
      />
      <SubTitle subtitle={<>2. 몇 가지 개념들</>} />
      <Paragraph
        paragraph={
          <>
            (1) 「인도」란 물건에 대한 직접적 지배, 즉 점유를 이전하는 것을
            의미합니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            통상적으로 인도라는 용어 이외에 「명도」라는 용어도 사용되는데,
            구법(구 민사소송법 제690조 제1항) 하에서 명도는 "부동산에서 살림,
            사무용품, 물품 등을 비치하고 거주, 영업 등을 하면서 그 부동산을
            점유하고 있는 경우에 그 부동산 내에 있는 점유자의 물품을 부동산
            밖으로 반출한 후 점유를 이전하는 것"을 의미하였지만, 현행 민사집행법
            제258조 제1항은 명도라는 개념을 인정하지 않고 인도의 개념에
            포함시키고 있습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (2) 「퇴거」란 건물 점유자의 점유를 풀어 그 건물로부터 점유자를
            쫓아내고 그 건물 내에 있는 점유자의 살림 등의 물품을 반출하는 것을
            의미합니다. 점유를 해제한다는 점에서 인도와 비슷하나, 퇴거는 점유의
            해제로 집행이 종료되고, 인도는 점유의 이전까지 나아간다는 점에서
            차이가 있습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            대표적으로 토지 소유자가 지상건물 소유자를 상대로 철거 청구를 하면서
            그 지상건물을 점유하고 있는 소유자가 아닌 제3자에게 퇴거 청구를 하는
            경우를 들 수 있습니다.
          </>
        }
      />
      <EmphasisParagraph
        paragraph={
          <>
            건물이 그 존립을 위한 토지사용권을 갖추지 못하여 토지의 소유자가
            건물의 소유자에 대하여 당해 건물의 철거 및 그 대지의 인도를 청구할
            수 있는 경우에라도 건물소유자가 아닌 사람이 건물을 점유하고 있다면
            토지소유자는 그 건물 점유를 제거하지 아니하는 한 위의 건물 철거 등을
            실행할 수 없다. 따라서 그때 토지소유권은 위와 같은 점유에 의하여 그
            원만한 실현을 방해당하고 있다고 할 것이므로, 토지소유자는 자신의
            소유권에 기한 방해배제로서 건물점유자에 대하여 건물로부터의 퇴출을
            청구할 수 있다(대법원 2010. 8. 19. 선고 2010다43801 판결).
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (3) 「철거」란 토지의 정착물 중 '건물'에 대한 현상의 변경을 수반하는
            점유이전을 가르킵니다(토지의 정착물 중 건물에 대하여는 「철거」,
            수목·입목에 대해서는 「수거」, 분묘에 대하여는 「굴이」 등의 용어를
            사용하고, 비정착물에 대한 제거를 구할 때는 「취거」라는 용어를
            사용하는 것이 관례입니다).
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            토지에 건물 등의 정착물이 있는 경우 토지를 인도하라는 집행권원만으로
            토지의 인도집행을 할 수 없으므로, 토지에 대한 인도판결 뿐 아니라
            정착물에 대한 판결(철거판결, 수거판결, 굴이판결 등)이 필요합니다.
          </>
        }
      />
      <EmphasisParagraph
        paragraph={
          <>
            ▲ 강제집행의 목적물이 아닌 동산은 집행관이 제거하여 채무자에게
            인도합니다(민사집행법 제258조 제3항).
          </>
        }
      />
      <EmphasisParagraph
        paragraph={
          <>
            토지의 인도를 명한 채무명의의 효력은 그 지상에 건립된 건물에까지
            미치는 것이 아니고 건물을 그대로 둔 채 토지에 대한 점유만을 풀어
            채권자에게 인도할 수도 없는 것이므로, 집행관으로서는 지상에 건물이
            건축되어 있는 토지에 대하여는 그 지상물의 인도나 철거 등을 명하는
            채무명의가 따로 없는 이상 토지를 인도하라는 채무명의만으로는
            인도집행을 실시할 수 없다(대법원 2018. 6. 29. 2018그552 결정).
          </>
        }
      />
      <SubTitle subtitle={<>3. 법무법인 다미의 제안</>} />
      <Paragraph
        paragraph={
          <>
            (1) 법무법인 다미는 변호사와의 직접 상담을 통하여,
            부동산점유이전금지가처분(소송 과정에서 부동산의 점유가 이전되면 또
            다른 분쟁이 발생할 수 있으므로, 가처분이 선행되어야 합니다)부터
            인도집행 등까지 부동산인도(명도)에 관련된 업무 전반을 논스톱으로
            처리해 드립니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (2) 법무법인 다미는 합리적인 비용으로 가장 유효적절한 권리구제
            방법을 제안해 드리며(소송상대방의 특정, 소송유형의 선택, 청구방법의
            결정 등에 대하여 상세하게 설명드리겠습니다), 채권자에게 불필요하거나
            실익 없는 분쟁을 유도하지 않습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (3) 인도소송을 진행하면서 발생할 수 있는 또 다른 법률적 분쟁에
            효과적으로대처할 수 있습니다.
          </>
        }
      />
      <TelButton />
    </Box>
  );
}
