import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import MemberFirst from "../../images/member/member-first.jpg";
import MemberSecond from "../../images/member/member-second.jpg";
import MemberThird from "../../images/member/member-third.jpg";
import Button from "@mui/material/Button";
import Carousel from "../carousel";
import store from "../../store.js";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

function isVisible(scrollSensor) {
  if (scrollSensor.current == null) return false;

  let scrollSensorTop = scrollSensor.current.getBoundingClientRect().top;
  let scrollSensorBottom = scrollSensor.current.getBoundingClientRect().bottom;

  if (
    scrollSensorBottom + 100 > 0 &&
    scrollSensorTop + 50 < window.innerHeight
  ) {
    return "visible";
  } else {
    return "invisible";
  }
}

const MemberButton = (props) => {
  let navigate = useNavigate();

  function move() {
    navigate("../member/m" + props.memidx, { replace: false });
  }

  return (
    <Box
      sx={{
        width: "20rem",
        height: "4rem",
        m: "1rem 0rem 2rem 0rem",
        backgroundColor: "primary.main",
        borderRadius: "1.2rem",
        boxShadow: 5,
      }}
    >
      <Button
        sx={{
          fontSize: "1.6rem",
          width: "100%",
          height: "100%",
          color: "primary.background",
          borderRadius: "1.2rem",
        }}
        onClick={move}
      >
        자세히 보기
      </Button>
    </Box>
  );
};

function MemberCard(props) {
  const memberImages = [MemberFirst, MemberSecond, MemberThird];

  const memberDatas = [
    [
      "방인석",
      "대표 변호사",
      "평택고",
      "고려대",
      "사법시험 52회",
      "사법연수원 42기",
    ],
    [
      "김진모",
      "파트너 변호사",
      "휘문고",
      "한양대",
      "사법시험 52회",
      "사법연수원 42기",
    ],
    [
      "하동규",
      "파트너 변호사",
      "이서고",
      "성균관대",
      "사법시험 52회",
      "사법연수원 42기",
    ],
  ];

  const memberCardSx = {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1.2rem",
    width: "24rem",
    m: "1rem 1rem 0rem 1rem",
    backgroundColor: "primary.background",
    opacity: 0.5,
    boxShadow: 5,
    transform: "translateY(25%)",
    "&.visible": {
      opacity: 1,
      transform: "translateY(0%)",
      "&.delay-0": {
        transition: "0.7s",
      },
      "&.delay-1": {
        transition: "1.4s",
      },
      "&.delay-2": {
        transition: "2.1s",
      },
    },
  };

  const imgSx = {
    width: "13rem",
    height: "13rem",
    objectFit: "cover",
    objectPosition: "0rem 0rem",
    borderRadius: "50%",
    boxShadow: "3",
    m: "1rem 0px 1rem 0rem",
  };

  const nameSx = {
    fontSize: "2rem",
    textAlign: "center",
    fontWeight: "700",
    m: "0px 0px 0px 0px",
  };

  const positionSx = {
    fontSize: "1.7rem",
    fontWeight: "700",
    textAlign: "center",
    m: "0.5rem 0rem 0rem 0rem",
  };

  const recordSx = {
    fontSize: "1.6rem",
    textAlign: "center",
    m: "0.5rem 0rem 0rem 0rem",
  };

  return (
    <Box
      className={`${useSelector((state) => state.memberCardVisible)} ${
        props.delay
      }`}
      sx={memberCardSx}
    >
      <Box
        sx={imgSx}
        component="img"
        alt="member"
        src={memberImages[props.memidx]}
      />
      <Box sx={nameSx}>{memberDatas[props.memidx][0]}</Box>
      <Box sx={positionSx}>{memberDatas[props.memidx][1]}</Box>
      <Box sx={recordSx}>{memberDatas[props.memidx][2]}</Box>
      <Box sx={recordSx}>{memberDatas[props.memidx][3]}</Box>
      <Box sx={recordSx}>{memberDatas[props.memidx][4]}</Box>
      <Box sx={recordSx}>{memberDatas[props.memidx][5]}</Box>
      <MemberButton memidx={props.memidx} />
    </Box>
  );
}

function PcMemberCardBox(props) {
  const scrollSensor = useRef(null);

  function onScroll() {
    if (scrollSensor.current === null) return;

    store.dispatch({
      type: "SET_MEMBER_CARD_VISIBLE",
      memberCardVisible: isVisible(scrollSensor),
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Box
      ref={scrollSensor}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        m: "0rem 0 0rem 0",
        width: "96rem",
        backgroundColor: `${props.backgroundColor}`,
      }}
    >
      <MemberCard memidx="0" delay="delay-0" />
      <MemberCard memidx="1" delay="delay-1" />
      <MemberCard memidx="2" delay="delay-2" />
    </Box>
  );
}

function MobileMemberCardBox(props) {
  return (
    <Carousel name="member" backgroundColor={props.backgroundColor}>
      <MemberCard memidx="0" />
      <MemberCard memidx="1" />
      <MemberCard memidx="2" />
    </Carousel>
  );
}

export function MemberCardBox(props) {
  if (useMediaQuery(isMobile)) {
    return <MobileMemberCardBox backgroundColor={props.backgroundColor} />;
  } else {
    return <PcMemberCardBox backgroundColor={props.backgroundColor} />;
  }
}
