import React from "react";
import { Title, SubTitle, Paragraph, TelButton } from "./service-elements";
import Box from "@mui/material/Box";

export function Service1(props) {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.background",
        borderRadius: "1.2rem",
      }}
    >
      <Title title={<>채권추심</>} />
      <SubTitle subtitle={<>1. 집행권원의 확보</>} />
      <Paragraph
        paragraph={
          <>
            채권추심을 위해서는 집행권원(판결, 지급명령, 이행권고결정, 공정증서,
            화해·조정·인낙조서 등)이 필요하므로, 채권자는 스스로 또는
            법률전문가의 도움을 받아 집행권원부터 확보해야 합니다.
          </>
        }
      />
      <SubTitle
        subtitle={
          <>2. 채무자의 재산 파악 ☞ 신용정보조사와 재산명시(및 재산조회)</>
        }
      />
      <Paragraph
        paragraph={
          <>
            (1) 채권자가 채무자의 재산(부동산, 동산, 자동차, 건설기계 등
            등록재산, 예·적금 등 금융재산, 급여, 퇴직금 등)을 파악하고 있다면
            해당 재산에 대한 강제집행을 통해 채권을 회수할 수 있겠지만, 그렇지
            못한 경우에는 채무자의 재산부터 파악해야 합니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            채무자의 재산을 파악하는 방법으로, ① 신용정보회사를 통하여 채무자의
            신용정보를 조사하는 방법, ② 법원을 통하여 채무자의 재산을 명시하게
            한 후 재산조회를 하는 방법 등이 있는데, 첫번째 방법은 신속성과 이에
            따른 밀행성이 보장된다는 장점이, 두번째 방법은 보다 많은 재산을
            발견할 가능성과 채무자에게 심리적 압박감을 줄 수 있다는 장점이
            있습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (2) 위 두 방법은 각기 장단점이 있고 어느 방법이 우월하고 어느 방법이
            열등하다고 단정할 수는 없으므로, 채권자와 채무자의 상황을 고려하여
            어떤 방법으로 채무자의 재산을 파악할지 결정해야 합니다.
          </>
        }
      />
      <SubTitle subtitle={<>3. 채권추심 ☞ 채무변제와 강제집행</>} />
      <Paragraph
        paragraph={
          <>
            채무자의 재산을 파악한 채권자의 입장에서는, 신용정보회사에
            채권추심을 위임할 수도 있고, 변호사를 포함한 법률전문가에게
            채권추심을 위임할 수도 있습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            채무자에게 변제자력이 있어 채무를 변제할 가능성이 있는 경우라면
            신용정보회사에 채권추심을 의뢰하여 도움을 받는 것도 하나의 방법이 될
            수 있겠지만, 채무자가 임의로 변제할 가능성이 크지 않은 경우에는(이
            글을 읽고 있는 채권자 대부분이 이에 해당할 것입니다) '변호사'만이
            소송과 강제집행 등 국가권력에 기한 청구권을 강제적으로 실현하여
            채권의 종국적인 만족을 얻거나 채권추심 과정에서 새롭게 발견되는
            사실관계를 법률적으로 해석하여 대처할 수 있으므로, 채권추심을
            위임함에 있어 이와 같은 사정을 고려하여야 할 것입니다.
          </>
        }
      />
      <SubTitle subtitle={<>4. 법무법인 다미의 제안</>} />
      <Paragraph
        paragraph={
          <>
            (1) 법무법인 다미는 변호사와의 직접 상담을 통하여, 집행권원 확보부터
            재산조사[신용정보조사/재산명시(및 재산조회)], 채무변제와 강제집행에
            이르기까지 채권추심에 관련된 업무 전반을 논스톱으로 처리해
            드립니다(처음부터 법률전문가에게 위임하는 편이 전체적으로 보아
            시간과 비용, 노력을 절약할 수 있습니다).
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (2) 법무법인 다미는 합리적인 비용으로 가장 유효적절한 추심 방법을
            제안해 드리며(채무자가 사업을 하고 있거나 근로소득자라면 금융재산
            또는 급여채권에 대한 압류 및 추심 등이 가능할 것입니다), 채권자에게
            불필요하거나 실익 없는 분쟁을 유도하지 않습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (3) 채권추심 과정에서 채무자가 재산을 은닉하거나 처분한 사실이
            확인될 경우 형사절차를 진행하거나 사해행위취소소송을 제기하는 등의
            방법으로 또 다른 법률적 분쟁에 대처할 수 있습니다.
          </>
        }
      />
      <TelButton />
    </Box>
  );
}
