import React from "react";
import {
  Title,
  SubTitle,
  Paragraph,
  TelButton,
  EmphasisParagraph,
} from "./service-elements";
import Box from "@mui/material/Box";

export function Service3(props) {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.background",
        borderRadius: "1.2rem",
      }}
    >
      <Title title={<>착오송금</>} />
      <SubTitle subtitle={<>1. 들어가며</>} />
      <Paragraph
        paragraph={
          <>
            "착오송금". 조심하지 않고 착각하여 돈을 잘못 보낸 경우를 말합니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            통상적으로 돈을 잘못 부친 송금인은 해당 금융기관에 요청하여 돈을
            돌려받을 수 있습니다. 이에 더하여 예금보험공사는 2021. 7. 6.부터
            착오송금 반환지원 서비스를 시행하고 있으므로, 이를 통해서도
            착오송금액을 돌려받을 수 있습니다(다만, 2021. 7. 6. 이전에
            송금하였거나 송금액이 1,000만원을 초과하는 경우, 수취인이 사망한
            경우 등에는 위 서비스를 이용할 수 없고, 또 예금보험공사의 양도통지가
            수취인에게 도달하지 않는 경우, 수취인에 대한 지급명령이 송달되지
            않는 경우 등에는 위 서비스가 해제될 수 있습니다, 예금자보호법, 동법
            시행령, 착오송금반환지원 규정, 착오송금반환지원 규정 시행세칙 참조).
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            그러나 수취인이 반환을 거절하거나 수취인이 사망한 경우 등에는 소송을
            통하여 착오송금액을 돌려받을 수 밖에 없습니다.
          </>
        }
      />
      <EmphasisParagraph
        paragraph={
          <>
            ▲ '착오송금 반환지원 서비스'는, 예금보험공사가 송금인에게
            착오송금액을 먼저 지급한 후 수취인에게 구상하는 것이 아니라,
            예금보험공사가 송금인으로부터 채권을 양도받아 수취인으로부터 채권을
            회수한 다음 회수금 중 일부를 송금인에게 지급하는 구조로써(착오송금
            반환지원 서비스는 무료가 아닙니다), 예금보험공사가 착오송금액을
            회수하지 못할 경우 그 목적을 달성할 수 없다는 한계가
            존재합니다(예금보험공사는 지급명령은 신청하지만 지급명령이 확정되지
            않을 경우 그 이후의 소송절차까지 마무리해 주지 않습니다).
            <br />
            <br />
            다만 이와 같은 내용은 저희가 검토한 것으로 오류가 있을 수 있으니,
            '착오송금 반환지원 서비스'에 관하여는 예금보험공사에 문의하시기
            바랍니다.
          </>
        }
      />
      <SubTitle subtitle={<>2. 법무법인 다미의 제안</>} />
      <Paragraph
        paragraph={
          <>
            (1) 우선 해당 금융기관 또는 예금보험공사를 통하여 착오송금액을
            반환받을 수 있는지 확인하시기 바랍니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (2) 착오송금액이 거액이거나 수취인이 반환을 거절하거나 수취인의 사망
            등을 이유로 착오송금액을 임의반환받는 것이 불가능한 상황이라면,
            법무법인 다미는 변호사와의 직접 상담을 통하여 부당이득반환의 소부터
            예금채권에 대한 집행까지 착오송금 반환에 관련된 업무 전반을
            논스톱으로 처리해 드립니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            (3) 법무법인 다미는 합리적인 비용으로 가장 유효적절한 권리구제
            방법을 제안해 드리며, 채권자에게 불필요하거나 실익 없는 분쟁을
            유도하지 않습니다(착오송금액의 규모에 따라 비용과 결과를
            비교형량하여 절차 진행에 대한 상세한 의견을 제시해 드리겠습니다).
          </>
        }
      />
      <TelButton />
    </Box>
  );
}
