import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Carousel from "../carousel";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";

const ServiceButton = (props) => {
  let navigate = useNavigate();

  function move() {
    navigate("../service/s" + props.seridx, { replace: false });
  }

  return (
    <Box
      sx={{
        width: "20rem",
        height: "4rem",
        m: "1rem 0rem 2rem 0rem",
        backgroundColor: "primary.main",
        borderRadius: "1.2rem",
        boxShadow: 5,
        position: "absolute",
        bottom: "0px",
      }}
    >
      <Button
        sx={{
          fontSize: "1.6rem",
          width: "100%",
          height: "100%",
          color: "primary.background",
          borderRadius: "1.2rem",
        }}
        onClick={move}
      >
        자세히 보기
      </Button>
    </Box>
  );
};

function ServiceCard(props) {
  const serviceDatas = [
    [
      ["사해행위"],
      [
        "사해행위는 채권자에게는 채권회수의 마지막 기회이지만, 채무자에게는 불의의 타격이 될 수 있습니다.",
        "사해행위의 전문가인 법무법인 다미와 상의하세요",
      ],
    ],
    [
      ["채권추심"],
      [
        "집행권원의 확보(지급명령, 소제기), 재산조회, 재산명시, 강제집행 등 채권회수의 전 과정을 법무법인 다미가 책임지고 진행해 드립니다.",
      ],
    ],
    [
      ["명도소송"],
      [
        "토지 및 건물 인도소송부터 집행에 이르기까지 법무법인 다미가 책임지고 진행해 드립니다.",
      ],
    ],
    [
      ["착오송금"],
      ["착오송금한 금액이 적다고 포기하지 마시고, 법무법인 다미와 상의하세요."],
    ],
  ];

  const serviceCardSx = {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "1rem",
    width: "22rem",
    height: "35rem",
    backgroundColor: "primary.background",
    boxShadow: 5,
    p: "0rem 1rem 0rem 1rem",
    position: "relative",
    m: "1rem 1.5rem 0rem 1.5rem",
  };

  const titleSx = {
    width: "22rem",
    fontSize: "3.5rem",
    textAlign: "center",
    fontWeight: "500",
    borderRadius: "0rem",
    backgroundColor: "white",
    borderBottom: 2,
    borderColor: "primary.main",
    p: "2.5rem 0rem 2.5rem 0rem",
  };

  const describeSx = {
    fontSize: "1.6rem",
    fontWeight: "500",
    textAlign: "center",
    m: "1rem 0rem 0rem 0rem",
    p: "0rem 0.3rem 0rem 0.3rem",
  };

  return (
    <Box sx={serviceCardSx}>
      <Box sx={titleSx}>{serviceDatas[props.seridx][0][0]}</Box>
      {serviceDatas[props.seridx][1].map((serviceDescribe, index) => (
        <Box key={`service-card-${index}`} sx={describeSx}>
          {serviceDescribe}
        </Box>
      ))}
      <ServiceButton seridx={props.seridx} />
    </Box>
  );
}

export function ServiceCardBox(props) {
  const theme = useTheme();

  return (
    <Carousel name="service" backgroundColor={props.backgroundColor}>
      <ServiceCard seridx="0" />
      <ServiceCard seridx="1" />
      <ServiceCard seridx="2" />
      <ServiceCard seridx="3" />
    </Carousel>
  );
}
