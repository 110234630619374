import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

function isVisible(scrollSensor) {
  if (scrollSensor.current == null) return false;

  let scrollSensorTop = scrollSensor.current.getBoundingClientRect().top;
  let scrollSensorBottom = scrollSensor.current.getBoundingClientRect().bottom;

  if (scrollSensorBottom + 100 > 0 && scrollSensorTop < window.innerHeight) {
    return true;
  } else {
    return false;
  }
}

export default function Carousel(props) {
  let visibleOrInvisible = false;

  const prevBtn = useRef(null);
  const nextBtn = useRef(null);
  const swiperRef = useRef(null);

  function stopCarousel() {
    console.log("stop carousel");
  }

  function moveCarousel() {
    console.log("move carousel");
  }

  const scrollSensor = useRef(null);
  const [visible, setVisible] = useState(isVisible(scrollSensor));

  function onScroll() {
    if (isVisible(scrollSensor) && !visibleOrInvisible) {
      visibleOrInvisible = true;
      setVisible(isVisible(scrollSensor));
      document.querySelector(`#${props.name}-carousel`).swiper.autoplay.start();
    }

    if (!isVisible(scrollSensor) && visibleOrInvisible) {
      visibleOrInvisible = false;
      setVisible(isVisible(scrollSensor));
      document.querySelector(`#${props.name}-carousel`).swiper.autoplay.stop();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Box
      ref={scrollSensor}
      className={`${visible ? "visible" : "invisible"}`}
      sx={{
        opacity: 0.5,
        transform: "translateY(20%)",
        backgroundColor: props.backgroundColor,
        "&.visible": {
          opacity: 1,
          transform: "translateY(0%)",
          transition: "1s",
        },
        ".swiper": {
          p: "0px 0px 30px 0px",
        },
        ".swiper-slide": {
          display: "flex !important",
          flexDirection: "row !important",
          alignItems: "center !important",
          justifyContent: "center !important",
        },
        ".swiper-pagination-bullets": {
          display: "flex !important",
          flexDirection: "row !important",
          alignItems: "center !important",
          justifyContent: "center !important",
          backgroundColor: "transparent",
          height: "30px",
          bottom: "0px !important",
        },
        ".swiper-pagination-bullet-active": {
          backgroundColor: "primary.main",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: "0px",
          backgroundColor: props.backgroundColor,
        }}
      >
        <IconButton
          className={`${props.name}-carousel-previous-button`}
          ref={prevBtn}
          sx={{
            width: "40px",
            height: "40px",
            m: "5px",
            color: "primary.main",
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box
          sx={{
            width: `${useMediaQuery(isMobile) ? "260px" : "960px"}`,
          }}
        >
          <Swiper
            id={`${props.name}-carousel`}
            ref={swiperRef}
            modules={[Navigation, Pagination, Autoplay]}
            navigation={{
              prevEl: `.${props.name}-carousel-previous-button`,
              nextEl: `.${props.name}-carousel-next-button`,
            }}
            spaceBetween={50}
            slidesPerView={`${useMediaQuery(isMobile) ? 1 : 3}`}
            pagination={{ clickable: true }}
            //onSlideChange={() => console.log("slide change")}
            //onSwiper={(swiper) => console.log("11" + swiper)}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = `.${props.name}-carousel-previous-button`;
              swiper.params.navigation.nextEl = `.${props.name}-carousel-next-button`;
            }}
          >
            {props.children.map((child, index) => (
              <SwiperSlide key={`carousel-slide-${index}`}>{child}</SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <IconButton
          className={`${props.name}-carousel-next-button`}
          ref={nextBtn}
          sx={{
            width: "40px",
            height: "40px",
            m: "5px",
            color: "primary.main",
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
