import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./components/nav";
import Main from "./pages/main";
import Member from "./pages/member";
import Service from "./pages/service";
import Map from "./pages/map";
import Footer from "./components/footer";
import Box from "@mui/material/Box";

function App() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/member/:memberid/*" element={<Member />} />
          <Route path="/service/:serviceid/*" element={<Service />} />
          <Route path="/map/" element={<Map />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
