import React from "react";
import MainContainer from "../main-container";
import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import { MemberCardBox } from "./member-elements";

export default function Member() {
  const theme = useTheme();

  return (
    <MainContainer
      outerContainerColor={theme.palette.secondary.main}
      innerContainerColor={theme.palette.secondary.main}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          p: "0rem 0rem 10rem 0rem",
        }}
      >
        <Box
          sx={{
            fontSize: "4rem",
            fontWeight: "600",
            textAlign: "center",
            m: "10rem 1rem 10rem 1rem",
          }}
        >
          구성원
        </Box>
        <MemberCardBox backgroundColor={theme.palette.secondary.main} />
      </Box>
    </MainContainer>
  );
}
