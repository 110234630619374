import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CallIcon from "@mui/icons-material/Call";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KaKaoSymbol from "../../images/kakao/symbol.svg";
import { isMobile } from "../../components/media-query";
import { useMediaQuery } from "react-responsive";

export function ServiceBox(props) {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "secondary.main",
      }}
    >
      <Box
        sx={{
          width: `${useMediaQuery(isMobile) ? "36rem" : "90rem"}`,
          backgroundColor: "primary.Background",
        }}
      >
        {props.serviceName}
      </Box>
    </Box>
  );
}

export function Title(props) {
  return (
    <Box
      sx={{
        backgroundColor: "primary.background",
        textAlign: "center",
        m: "2rem 2rem 4rem 2rem",
        p: "0rem 0rem 1rem 0rem",
        fontSize: "4.5rem",
        fontWeight: 600,
        borderBottom: "0.5rem double",
        borderColor: "primary.main",
      }}
    >
      {props.title}
    </Box>
  );
}

export function SubTitle(props) {
  return (
    <Box
      sx={{
        backgroundColor: "primary.background",
        textAlign: "left",
        m: "5rem 2rem 2rem 2rem",
        p: "0rem 0rem 0rem 2rem",
        fontSize: "2rem",
        fontWeight: 600,
        borderLeft: "0.5rem solid",
        borderColor: "primary.main",
      }}
    >
      {props.subtitle}
    </Box>
  );
}

export function Paragraph(props) {
  return (
    <Box
      sx={{
        backgroundColor: "primary.background",
        textAlign: "left",
        m: "3.5rem 2rem 1rem 2rem",
        p: "0rem 0rem 0rem 0rem",
        fontSize: "1.6rem",
        fontWeight: 500,
        textAlign: "justify",
      }}
    >
      {props.paragraph}
    </Box>
  );
}

export function EmphasisParagraph(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        m: "2rem 2rem 2rem 2rem",
        p: "0rem 1rem 0rem 1rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "justify",
          backgroundColor: "primary.background",
          m: "0rem 0rem 0rem 0rem",
          p: "1rem 1rem 1rem 1rem",
          fontSize: "1.7rem",
          fontWeight: 500,
          boxShadow: 3,
          borderRadius: "1rem",
          border: "0.1rem solid",
          borderColor: "primary.main",
        }}
      >
        {props.paragraph}
      </Box>
    </Box>
  );
}

export function Accordions(props) {
  const [expanded, setExpanded] = React.useState("none");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "none");
  };

  return (
    <Box sx={{ m: "0rem 2rem 0rem 2rem" }} className="accordions">
      {props.accordionDatas.map((accordionData, index) => (
        <ServiceAccordion
          expanded={expanded}
          handleChange={handleChange}
          key={`panel${index}`}
          name={`panel${index}`}
          summary={accordionData[0]}
          details={accordionData[1]}
          image={props.accordionImages[index]}
        />
      ))}
    </Box>
  );
}

function ServiceAccordion(props) {
  return (
    <MuiAccordion
      sx={{
        p: "0rem",
        m: "0rem",
        backgroundColor: "primary.background",
        color: "black",
        border: "0.1rem solid",
        borderColor: "primary.main",
        borderRadius: "0rem",
      }}
      expanded={props.expanded === props.name}
      onChange={props.handleChange(props.name)}
    >
      <MuiAccordionSummary
        sx={{
          minHeight: "0px !important",
          p: "0rem",
          m: "1rem 0rem 1rem 1rem !important",
          fontSize: "1.6rem",
          "& .MuiAccordionSummary-content": {
            m: "0rem !important",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            m: "0rem 1rem 0rem 1rem",
          },
        }}
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: "primary.main",
            }}
          />
        }
        aria-controls={`${props.name}-content`}
        id={`${props.name}-header`}
      >
        <Box sx={{ textAlign: "justify" }}>{props.summary}</Box>
      </MuiAccordionSummary>
      <MuiAccordionDetails
        sx={{ m: "0rem 1rem 1rem 1rem", p: "0rem 0rem 0rem 0rem" }}
      >
        <Box sx={{ borderTop: "0.1rem solid" }}>
          <Box
            sx={{
              m: "1rem 0rem 0rem 0rem",
              textAlign: "justify",
              fontSize: "1.6rem",
            }}
          >
            {props.details}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              m: "1.5rem 0rem 1.5rem 0rem",
              p: "0rem",
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            <Box
              sx={{
                m: "0rem",
                p: "0rem",
                boxShadow: 2,
                borderRadius: "1.2rem",
                width: "96%",
              }}
              component="img"
              alt="case"
              src={props.image}
            />
          </Box>
        </Box>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}

function KaKaoSymbolBox() {
  return (
    <Box
      sx={{
        width: "3.5rem",
        height: "auto",
        m: "0rem 0.5rem 0rem 0rem",
      }}
      component="img"
      alt="kakao-symbol"
      src={KaKaoSymbol}
    />
  );
}

export function TelButton(props) {
  function chat() {
    window.Kakao.Channel.chat({
      channelPublicId: "_XxegGb",
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: `${useMediaQuery(isMobile) ? "column" : "row"}`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "22rem",
          height: "5.8rem",
          m: `${
            useMediaQuery(isMobile)
              ? "2rem 0rem 0rem 0rem"
              : "2rem 2rem 3rem 0rem"
          }`,
          backgroundColor: "primary.main",
          borderRadius: "1.2rem",
          boxShadow: 5,
        }}
      >
        <Button
          sx={{
            width: "100%",
            height: "100%",
            color: "primary.background",
            borderRadius: "1.2rem",
            fontSize: "2.5rem",
            "& svg": {
              width: "2.8rem",
              height: "auto",
            },
          }}
          href="tel:0327103930"
          startIcon={<CallIcon />}
        >
          032-710-3930
        </Button>
      </Box>
      <Box
        sx={{
          width: "22rem",
          height: "5.8rem",
          m: `${
            useMediaQuery(isMobile)
              ? "2rem 0rem 3rem 0rem"
              : "2rem 2rem 3rem 2rem"
          }`,
          backgroundColor: "#FEE500",
          borderRadius: "1.2rem",
          boxShadow: 5,
        }}
      >
        <Button
          sx={{
            width: "100%",
            height: "100%",

            color: "rgba(0, 0, 0, 0.85)",
            borderRadius: "1.2rem",
            fontSize: "2.5rem",
            fontWeight: 800,
          }}
          onClick={chat}
          startIcon={<KaKaoSymbolBox />}
        >
          카톡상담
        </Button>
      </Box>
    </Box>
  );
}
