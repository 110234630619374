import React from "react";
import Box from "@mui/material/Box";
import { isMobile } from "../../components/media-query";
import { useMediaQuery } from "react-responsive";

function NameAndPosition(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: `${useMediaQuery(isMobile) ? "column" : "row"}`,
        alignItems: `${useMediaQuery(isMobile) ? "center" : "flex-end"}`,
        justifyContent: "center",
        m: "1rem 0rem 1rem 0rem",
      }}
    >
      <Box
        sx={{ fontSize: "3.6rem", fontWeight: 600, m: "0rem 0rem 0rem 0rem" }}
      >
        {props.name}
      </Box>
      <Box
        sx={{ fontSize: "2rem", fontWeight: 500, m: "0rem 0.5rem 0rem 0rem" }}
      >
        {props.position}
      </Box>
    </Box>
  );
}

function Contact(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "center",
        m: `${
          useMediaQuery(isMobile)
            ? "0rem 0rem 0rem 0rem"
            : "0rem 1rem 0rem 0rem"
        }`,
      }}
    >
      <Box
        sx={{
          fontSize: "1.6rem",
          fontWeight: 700,
          m: "0rem 0.5rem 0rem 0rem",
        }}
      >
        {props.first}
      </Box>
      <Box
        sx={{
          fontSize: "1.6rem",
        }}
      >
        {props.second}
      </Box>
    </Box>
  );
}

function Contacts(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: `${useMediaQuery(isMobile) ? "column" : "row"}`,
        alignItems: `${useMediaQuery(isMobile) ? "center" : "flex-end"}`,
        justifyContent: "center",
      }}
    >
      {props.contacts.map((contact, index) => (
        <Contact
          key={`contact-${index}`}
          first={contact[0]}
          second={contact[1]}
        />
      ))}
    </Box>
  );
}

function Info(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: `${useMediaQuery(isMobile) ? "center" : "flex-start"}`,
        justifyContent: "center",
        m: `${
          useMediaQuery(isMobile)
            ? "1rem 0rem 1rem 0rem"
            : "0rem 0rem 0rem 0rem"
        }`,
      }}
    >
      <NameAndPosition name={props.name} position={props.position} />
      <Contacts contacts={props.contacts} />
    </Box>
  );
}

function Photo(props) {
  return (
    <Box
      sx={{
        width: "20rem",
        m: "2rem 2rem 0rem 2rem",
        boxShadow: 5,
        borderRadius: "1rem",
      }}
      component="img"
      alt="member"
      src={props.photo}
    />
  );
}

export function Header(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: `${useMediaQuery(isMobile) ? "column" : "row"}`,
        alignItems: `${useMediaQuery(isMobile) ? "center" : "flex-end"}`,
        justifyContent: `${useMediaQuery(isMobile) ? "center" : "flex-start"}`,
        m: "0rem 0rem 2rem 0rem",
      }}
    >
      <Photo photo={props.photo} />
      <Info
        name={props.name}
        position={props.position}
        contacts={props.contacts}
      />
    </Box>
  );
}

export function Intro(props) {
  return (
    <Box
      sx={{
        fontSize: "1.6rem",
        m: "0rem 2rem 2rem 2rem",
        textAlign: "justify",
      }}
    >
      {props.intro}
    </Box>
  );
}

export function Career(props) {
  return (
    <Box sx={{ m: "2rem 0rem 2rem 2rem", p: "0rem 1rem 0rem 0rem" }}>
      <Box
        sx={{ fontSize: "1.8rem", fontWeight: 600, m: "0rem 0rem 0.5rem 0rem" }}
      >
        {props.title}
      </Box>
      {props.careers.map((career, index) => (
        <Box
          sx={{ fontSize: "1.5rem", m: "0rem 0rem 0rem 1rem" }}
          key={`career-${index}`}
        >
          {career}
        </Box>
      ))}
    </Box>
  );
}
