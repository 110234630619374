import React, { useEffect } from "react";
import store from "../../store.js";
import Box from "@mui/material/Box";
import { Service0 } from "./service-0";
import { Service1 } from "./service-1";
import { Service2 } from "./service-2";
import { Service3 } from "./service-3";
import { ServiceCardBox } from "../../components/service/service-elements";
import { useParams } from "react-router-dom";
// import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/system";
import { isMobile } from "../../components/media-query";
import { useMediaQuery } from "react-responsive";

export default function Service() {
  let { serviceid } = useParams();

  // const [searchParams] = useSearchParams({});
  // const ser = searchParams.get("ser");

  const Services = [Service0, Service1, Service2, Service3];

  let Service = Services[0];

  switch (serviceid) {
    case "s0":
      Service = Services[0];
      break;
    case "s1":
      Service = Services[1];
      break;
    case "s2":
      Service = Services[2];
      break;
    case "s3":
      Service = Services[3];
      break;
  }

  window.scrollTo(0, 0);

  useEffect(() => {
    store.dispatch({
      type: "CHANGE_NAV_COLOR",
      navColor: "opaque",
    });
  }, []);

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        backgroundColor: "secondary.main",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: `${useMediaQuery(isMobile) ? "320px" : "940px"}`,
          backgroundColor: "primary.background",
          boxShadow: 5,
          m: "10rem 0rem 10rem 0rem",
          borderRadius: "1.2rem",
        }}
      >
        <Service />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50rem",
          backgroundColor: "primary.background",
        }}
      >
        <ServiceCardBox backgroundColor={theme.palette.primary.background} />
      </Box>
    </Box>
  );
}
