import React from "react";
import Box from "@mui/material/Box";
import { Header, Intro, Career } from "./member-elements";
import Photo from "../../images/member/member-first.jpg";

export function Member0() {
  return (
    <Box>
      <Header
        photo={Photo}
        name="방인석"
        position="대표 변호사"
        contacts={[
          ["Tel.", "032-710-3930"],
          ["Fax.", "032-710-3931"],
          ["Email.", "bis@damilaw.kr"],
        ]}
      />
      <Intro
        intro={
          <>
            방인석 대표변호사는 사법연수원 수료 후 약 7년 동안 법무법인 헌암에서
            송무변호사로 활동하였고, 이후 공공기관(한국환경공단,
            한국데이터산업진흥원)을 거쳐 2020. 4.부터 (주)대방건설의 법무실장을
            역임하였으며, 2021. 8. 법무법인 다미의 대표변호사로 취임하였습니다.
          </>
        }
      />
      <Intro
        intro={
          <>
            주요 경력으로, 한국토지주택공사, 한국자산관리공사, 신용보증기금,
            기술보증기금, 한국주택금융공사, 농협은행, 농협중앙회, 지역
            농·축·수협, 서울보증보험(주), 인천광역시 연수구 등의 위임을 받은
            민사소송과 행정소송 수 백건을 진행하였고, 동시에 소송 등과 관련된
            자문업무를 수행하였으며, 공공기관 및 (주)대방건설 재직 당시에는 내부
            법률이슈·계약서 검토와 함께 감사, 대관업무를 지원하였습니다.
          </>
        }
      />
      <Career
        title="학력"
        careers={[<>- 평택고등학교 졸업</>, <>- 고려대학교 졸업</>]}
      />
      <Career
        title="주요 경력"
        careers={[
          <>- 2021~현재 법무법인 다미 파트너 변호사</>,
          <>- 2021~현재 인천광역시 부평구 법률고문</>,
          <>- 2010 제52회 사법시험 합격</>,
          <>- 2012 제주지방검찰청 검사직무대리</>,
          <>- 2012 서울북부지방법원 조정위원</>,
          <>- 2013 제42기 사법연수원 수료</>,
          <>- 2013 법무법인 헌암 소속 변호사</>,
          <>- 2019 한국환경공단 법무지원실</>,
          <>- 2019 한국데이터산업진흥원 법무지원실</>,
          <>- 2020 (주)대방건설 법무실 법무실장</>,
        ]}
      />
    </Box>
  );
}
