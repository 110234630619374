import { createStore } from "redux";

export default createStore(function (state, action) {
  if (state === undefined) {
    return {
      navColor: "transparent",
      deviceType: "mobile",
      introCardVisible: "visible",
      memberCardVisible: "visible",
      serviceCardVisible: "visible",
      mapVisible: "visible",
    };
  }

  if (action.type === "CHANGE_NAV_COLOR") {
    return { ...state, navColor: action.navColor };
  }
  if (action.type === "CHANGE_DEVICE_TYPE") {
    return { ...state, deviceType: action.deviceType };
  }
  if (action.type === "SET_INTRO_CARD_VISIBLE") {
    return { ...state, introCardVisible: action.introCardVisible };
  }
  if (action.type === "SET_MEMBER_CARD_VISIBLE") {
    return { ...state, memberCardVisible: action.memberCardVisible };
  }
  if (action.type === "SET_SERVICE_CARD_VISIBLE") {
    return { ...state, serviceCardVisible: action.serviceCardVisible };
  }
  if (action.type === "SET_MAP_VISIBLE") {
    return { ...state, mapVisible: action.mapVisible };
  }
  return state;
});
