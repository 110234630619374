import React from "react";
import Box from "@mui/material/Box";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  let divSx = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItem: "center",
    width: "100%",
    minWidth: "1024px",
    color: "primary.background",
    backgroundColor: "primary.main",
    p: "20px 0px 30px 0px",
  };

  let pSx = {
    fontSize: "16px",
    textAlign: "center",
    m: "10px 0px 0px 0px",
  };

  if (useMediaQuery(isMobile)) {
    divSx = { ...divSx, ...{ p: "15px 0px 20px 0px", minWidth: "340px" } };
    pSx = { ...pSx, ...{ m: "5px 0px 0px 0px", fontSize: "10px" } };
    return (
      <Box sx={divSx}>
        <Box sx={pSx}>
          주소: 인천광역시 미추홀구 소성로 171, 대흥동 2층 204호
        </Box>
        <Box sx={pSx}>(학익동, 대흥평창로시스빌딩)</Box>
        <Box sx={pSx}>
          Tel: 031-710-3930 | Fax: 031-710-3931 | e-mail: dami@damilaw.kr
        </Box>
        <Box sx={pSx}>사업자등록번호: 174-88-02006</Box>
        <Box sx={pSx}>광고책임변호사: 김진모</Box>
        <Box sx={pSx}>Copyright 2021. 법무법인 다미 all rights reserved.</Box>
      </Box>
    );
  } else {
    return (
      <Box sx={divSx}>
        <Box sx={pSx}>
          주소: 인천광역시 미추홀구 소성로 171, 대흥동 2층 204호(학익동,
          대흥평창로시스빌딩)
        </Box>
        <Box sx={pSx}>
          Tel: 032-710-3930 | Fax: 032-710-3931 | e-mail: dami@damilaw.kr
        </Box>
        <Box sx={pSx}>사업자등록번호: 174-88-02006</Box>
        <Box sx={pSx}>광고책임변호사: 김진모</Box>
        <Box sx={pSx}>Copyright 2021. 법무법인 다미 all rights reserved.</Box>
      </Box>
    );
  }
}
