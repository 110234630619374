import React, { useEffect } from "react";
import store from "../../store.js";
import Box from "@mui/material/Box";
import { Member0 } from "./member-0";
import { Member1 } from "./member-1";
import { Member2 } from "./member-2";
import { MemberCardBox } from "../../components/member/member-elements";
import { useParams } from "react-router-dom";
// import { useSearchParams } from "react-router-dom";
import { isMobile } from "../../components/media-query";
import { useMediaQuery } from "react-responsive";

export default function Member() {
  let { memberid } = useParams();

  // const [searchParams] = useSearchParams({});
  const Members = [Member0, Member1, Member2];

  let Member = Members[0];

  switch (memberid) {
    case "m0":
      Member = Members[0];
      break;
    case "m1":
      Member = Members[1];
      break;
    case "m2":
      Member = Members[2];
      break;
  }

  window.scrollTo(0, 0);

  useEffect(() => {
    store.dispatch({
      type: "CHANGE_NAV_COLOR",
      navColor: "opaque",
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "secondary.main",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: `${useMediaQuery(isMobile) ? "center" : "flex-start"}`,
            width: `${useMediaQuery(isMobile) ? "32rem" : "94rem"}`,
            minWidth: `${useMediaQuery(isMobile) ? "32rem" : "94rem"}`,
            backgroundColor: "primary.background",
            boxShadow: 5,
            m: "10rem 0rem 10rem 0rem",
            borderRadius: "1.2rem",
          }}
        >
          <Member />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50rem",
          backgroundColor: "primary.background",
        }}
      >
        <MemberCardBox backgroundColor="primary.background" />
      </Box>
    </>
  );
}
