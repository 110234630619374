import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import IntroFirst from "../../images/intro/intro-first.jpg";
import IntroSecond from "../../images/intro/intro-second.jpg";
import IntroThird from "../../images/intro/intro-third.jpg";
import IntroForth from "../../images/intro/intro-forth.jpg";
import { isMobile } from "../media-query";
import { useMediaQuery } from "react-responsive";

export function IntroCard(props) {
  let introCardWidth = "16rem";

  if (useMediaQuery(isMobile)) {
    introCardWidth = "16rem";
  } else {
    introCardWidth = "17rem";
  }

  const introCardSx = {
    overflow: "hidden",
    borderRadius: "1.2rem",
    width: introCardWidth,
    height: "24rem",
    opacity: 0.5,
    boxShadow: 5,
    transform: "translateY(25%)",
    "&.visible": {
      opacity: 1,
      transform: "translateY(0%)",
      "&.delay-0": {
        transition: "0.5s",
      },
      "&.delay-1": {
        transition: "1s",
      },
      "&.delay-2": {
        transition: "1.5s",
      },
      "&.delay-3": {
        transition: "2s",
      },
      "&.delay-m-0": {
        transition: "0.6s",
      },
      "&.delay-m-1": {
        transition: "1.2s",
      },
      "&.delay-m-2": {
        transition: "1.8s",
      },
      "&.delay-m-3": {
        transition: "2.4s",
      },
    },
  };

  const imgSx = {
    width: introCardWidth,
    height: "10rem",
    objectFit: "cover",
  };

  const titSx = {
    fontSize: "1.8rem",
    textAlign: "center",
    fontWeight: "700",
    p: "1rem 0.5rem 1rem 0.5rem",
  };

  const descSx = {
    fontSize: "1.4rem",
    textAlign: "center",
    p: "0rem 0.5rem 0rem 0.5rem",
  };

  const images = [IntroFirst, IntroSecond, IntroThird, IntroForth];

  return (
    <Box
      className={`${useSelector((state) => state.introCardVisible)} ${
        props.delay
      }`}
      sx={introCardSx}
    >
      <Box sx={imgSx} component="img" alt="logo" src={images[props.imgidx]} />
      <Box sx={titSx}>{props.tit}</Box>
      <Box sx={descSx}>{props.desc}</Box>
    </Box>
  );
}
