import React, { useEffect } from "react";
import { NaverMap } from "../../components/map/map-elements";
import MainContainer from "../../components/main-container";
import store from "../../store.js";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";

export default function Map() {
  window.scrollTo(0, 0);

  useEffect(() => {
    store.dispatch({
      type: "CHANGE_NAV_COLOR",
      navColor: "opaque",
    });
  }, []);

  const theme = useTheme();

  return (
    <MainContainer
      outerContainerColor={theme.palette.secondary.main}
      innerContainerColor={theme.palette.secondary.main}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          p: "12rem 0rem 12rem 0rem",
        }}
      >
        <NaverMap />
      </Box>
    </MainContainer>
  );
}
