import React from "react";
import {
  Title,
  SubTitle,
  Paragraph,
  EmphasisParagraph,
  TelButton,
  Accordions,
} from "./service-elements";
import Box from "@mui/material/Box";
import Image0 from "../../images/service/service0/image0.png";

export function Service0(props) {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.background",
        borderRadius: "1.2rem",
      }}
    >
      <Title title={<>사해행위</>} />
      <SubTitle
        subtitle={
          <>사해행위소송을 통해 채무자가 빼돌린 재산을 찾아올 수 있습니다.</>
        }
      />
      <Paragraph
        paragraph={
          <>
            우리 민법은 채권자평등주의를 채택하고 있습니다. 즉 채무초과상태에
            있는 채무자는 본인 재산을 모든 채권자에게 평등하게 변제하는데
            사용하여야 하고, 특정 채권자에게 변제하는데 사용하거나 제3자에게
            이전시킬 수 없습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            이러한 원칙을 보장하기 위해 민법은 채무자가 채무초과 상태에서
            부동산을 타인 명의로 이전하거나, 부동산에 근저당권 등을 설정하여
            강제집행이 여러운 경우, 사해행위소송을 통해 부동산을 채무자 명의로
            되돌리거나, 부동산에 설정된 근저당권을 말소시킬 수 있도록 하고
            있습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            따라서 사해행위소송을 통해 채무자 명의로 채무자의 재산을 되돌린뒤
            강제집행을 할 수 있고, 경우에 따라서는 채무자로부터 재산을 이전받은
            사람으로부터 직접 변제를 받을 수도 있습니다.
          </>
        }
      />
      <SubTitle
        subtitle={
          <>
            법무법인 다미의 변호사들은 모두 사해행위취소소송의 전문가들입니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            방인석, 김진모, 하동규 변호사는 사법연수원 수료 후 법무법인 헌암에서
            함께 근무하였습니다. ① 하동규 변호사는 2013년 3월부터 2021년
            6월까지, ② 방인석 변호사는 2013년 3월부터 2019년 6월까지 ③ 김진모
            변호사는 2013년 12월부터 2015년 8월까지 각 근무하였습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            법무법인 헌암은 신용보증기금, 서울보증보험 등의 보증기관의 송무를 주
            업무로 하였고, 2015년부터 2020년까지 신용보증기금으로부터 소송위임을
            받은 건수가 약 500건에 달하고 있습니다(공공기관 정보공개시스템
            알리오).
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            방인석, 하동규, 김진모 변호사 모두 법무법인 헌암 재직 당시
            신용보증기금 등의 위임을 받아 수 많은 사해행위 사건을 처리한 경험이
            있습니다. 또한 김진모 변호사는 2016년 7월부터 2021년 6월까지
            농림수산업자 신용보증기금(관리기관 농협중앙회)의 유일한
            사내변호사로서 소송수행 및 법률자문 업무를 수행하여 보증기금의 내부
            사정도 잘 알고 있습니다.
          </>
        }
      />
      <EmphasisParagraph
        paragraph={
          <>
            법무법인 다미는 그 간의 경력을 바탕으로 2022년 신용보증기금 및
            한국주택금융공사의 소송위임변호사로 선정되었습니다.
          </>
        }
      />
      <SubTitle
        subtitle={<>사해행위소송에는 수 많은 쟁점이 포함되어 있습니다.</>}
      />
      <Paragraph
        paragraph={
          <>
            사해행위취소소송의 경우, 1) 소극적 소송요건으로 제척기간
            5년(법률행위 있은 날 기준)/1년(취소원인을 안 날 기준)을 준수해야
            하고, 2) 객관적 요건으로 피보전채권의 존재와 사해행위의 존재가
            요구되며, 3) 주관적 요건으로 채무자의 사해의사와 수익자(또는
            전득자)의 사해의사가 필요합니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            사해행위의 유형으로, 매매, (근)저당권설정, 전세권설정, 임대차,
            변제(예외적인 경우), 대물변제(예약), 물상보증, 증여, 채권양도,
            상속재산 분할협의, (이혼을 원인으로 하는) 재산분할 등이 있는데, 각
            사해행위 별로 사해성이 부정되는 독자적인 사유가 존재하므로 제소나
            응소에 앞서 그에 대한 별도의 검토가 필요합니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            사해행위취소소송에 있어 수익자(전득자)의 사해의사, 즉 「재산권을
            목적으로 하는 법률행위로 인하여 공동담보에 부족이 생겨 채권자의
            채권을 완전하게 만족시킬 수 없게 된다는 사실의 인식」 여부가 가장
            문제되는데, 정상적인 거래인 경우 사해의사가 부정될 수 있으므로,
            취소채권자나 수익자(전득자) 모두 정상적인 거래를 통해 재산권을
            취득하였는지 살펴보아야 합니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            대부분의 경우 사해행위취소와 함께 원상회복도 구하는데,
            원상회복방법에는 원물반환, 가액배상, 채권양도, 배당표경정 등 여러
            가지 방법이 있으므로, 집행이 가능하고 취소채권자에게 가장 유리한
            내용으로 원상회복 청구를 특정하는 절차를 거쳐야 합니다(원상회복
            청구를 제대로 특정하지 못할 경우 승소판결에도 불구하고 집행이
            불가능한 상황이 될 수도 있습니다).
          </>
        }
      />
      <SubTitle
        subtitle={
          <>
            사해행위소송은 채권자에게는 채권회수의 마지막 기회이지만,
            채무자에게는 불의의 타격이 될 수 있습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            사해행위소송을 통해 채무자가 제3자에게 이전시킨 재산을 채무자에게
            돌려 놓은 후 강제집행하거나, 제3자로부터 변제받을 수 있습니다.
            사해행위소송이 아니라면 채무자가 장래 재산을 취득하는 것을 기약없이
            기다리는 수 밖에 없습니다.사해행위소송을 통해 채무자가 제3자에게
            이전시킨 재산을 채무자에게 돌려 놓은 후 강제집행하거나, 제3자로부터
            변제받을 수 있습니다. 사해행위소송이 아니라면 채무자가 장래 재산을
            취득하는 것을 기약없이 기다리는 수 밖에 없습니다.
          </>
        }
      />
      <Paragraph
        paragraph={
          <>
            반대로 채무자와 거래한 상대방은 사해행위소송에서 패소하게 되는 경우
            채무자로부터 이전받은 재산을 되돌려 주고 채무자에게 손해배상을
            청구해야 하지만, 이미 변제자력이 없는 채무자에게 손해배상을 구하는
            것은 실익이 없는 경우가 많습니다.
          </>
        }
      />
      <SubTitle subtitle={<>승소사례</>} />
      <Accordions
        accordionDatas={[
          [
            <>
              채무자와 제3자간의 매매계약이 사해행위임을 이유로 취소하고,
              제3자로부터 채권을 회수한 사례
            </>,
            <>
              본 사안의 경우 채무자가 본인 소유의 부동산을 제3자에게 매도하여
              채무초과 상태에 이르렀기 때문에, 해당 매매계약은 사해행위에
              해당합니다.
              <br />
              <br />
              또한 매매계약 체결 이후 제3자가 해당 부동산을 재차 매도하여
              원물반환이 아닌 가액배상을 청구할 수 있었습니다.
              <br />
              <br />
              이에 최종적으로 채무자와 제3자 사이에 체결된 부동산 매매계약을
              취소하고 제3자로부터 약 1억 3,000만원을 변제받을 수 있었습니다.
            </>,
          ],
        ]}
        accordionImages={[[Image0]]}
      />
      <SubTitle subtitle={<>다미의 제안</>} />
      <EmphasisParagraph
        paragraph={
          <>
            사해행위소송을 준비 중이시거나, 사해행위소송을 당하셨다면 법무법인
            다미와 상담해 보세요.
            <br />
            <br />
            채권자라면 사해행위소송을 통해 채권회수가 가능한지 여부, 채무자와
            거래한 상대방이시라면 승소방안 및 손해를 최소한으로 줄일 수 있는
            방법을 안내드릴 수 있습니다.
            <br />
            <br />
            다만 법무법인 다미는 신용보증기금, 한국주택금융공사의
            소송위임변호사이므로, 위 기관들과 이해충돌의 우려가 있는 사안에
            관하여는 상담이 불가능할 수 있는 점 양해부탁드립니다.
          </>
        }
      />
      <TelButton />
      <div
        id="kakao-talk-channel-chat-button"
        data-channel-public-id="_xcLqmC"
        data-title="consult"
        data-size="small"
        data-color="yellow"
        data-shape="pc"
        data-support-multiple-densities="true"
      ></div>
    </Box>
  );
}
